// ***********************************
// FORMS
// ***********************************

input,
select,
textarea {
  font-size: 100%; /* Corrects font size not being inherited in all browsers */
  margin: 0; /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */
  vertical-align: baseline; /* Improves appearance and consistency in all browsers */
}

textarea {
  overflow: auto; /* Removes default vertical scrollbar in IE6/7/8/9 */
  padding-left: 3px;
  vertical-align: top; /* Improves readability and alignment in all browsers */
}

label{
  padding-right: 10px;
}



input, textarea {
  border: 1px solid #BBD1E3;
  line-height: 20px;
  padding: 4px 10px;
  width: 100%;

}

select {
  border: 1px solid #DDDDDD;
  color: #7F7F7F;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 5px;
  border-radius:5px;
}

input[type="color"]{
  border: none;
  background: none;
  padding: 0;
}


input[type="checkbox"],
input[type="radio"] {
  padding: 0; /* Addresses excess padding in IE8/9 */
}
input[type="search"] {
  -webkit-appearance: textfield; /* Addresses appearance set to searchfield in S5, Chrome */
  -webkit-box-sizing: content-box; /* Addresses box sizing set to border-box in S5, Chrome (include -moz to future-proof) */
  -moz-box-sizing:    content-box;
  box-sizing:         content-box;
}
input[type="search"]::-webkit-search-decoration { /* Corrects inner padding displayed oddly in S5, Chrome on OSX */
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner { /* Corrects inner padding and border displayed oddly in FF3/4 www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/ */
  border: 0;
  padding: 0;
}

.custom-input,
.input-like-textarea{
  border: 1px solid #BBD1E3;
  line-height: 20px;
  padding: 4px 10px;
  width: 100%;
  border-radius: 0;
  margin-top: 20px;
  font-size: 12px;

  @include placeholder{
    color: #535353;
    text-transform: uppercase;
    font-weight: 300;
  }
}
.input-like-textarea{
  min-height: 200px;
  resize: vertical;
}

.btn-t1 {
  margin-top: 20px;
  display: inline-block;
  padding: 9px 15px;
  line-height: 20px;
  height: 40px;
  border: 1px solid $blue;
  background-color: $blue;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
  color: $white;
  position: relative;
  @include transition-all(0.4s);

  &:hover{
    color: $blue;
    background-color: #EEF3F9;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

#check input{display: none;}
#check>div:first-of-type{margin-left: 30px;}
#gender-select>div:first-of-type{margin-right: 30px;}

/*fake radio*/
.radio-box label{position: relative; line-height: 22px; padding: 10px 0 10px 35px; margin-top: 20px; font-weight: 400; cursor: pointer;}
.radio-box label:before{
  position: absolute;
  content:"";
  width: 22px; height: 22px;
  border: 1px solid #ccc;
  background-color: #eee;
  border-radius: 50%;
  left: 0;
  top:10px;
}
.radio-box label:after{
  position: absolute;
  content:"";
  width: 12px; height: 12px;
  background-color: $red; /*ovo je backup color*/
  border-radius: 50%;
  left: 5px;
  top: 15px;
  display: none;
}
.radio-box label.active:after{display: inline-block;}
label.radio-left{ padding-left: 30px;}

.terms_box{margin-top: 40px;}
.terms_box a.underline:hover{
  color: $blue;
}

/*fake checkbox*/
.fake-checkbox label:after{content: "\f00c"; font-family: FontAwesome; position: absolute; right: 3px; top: 12px; display: none; color: $black;}
.fake-checkbox label.active:after{display: inline-block;}

.fake-checkbox {

  label{
    position: relative;
    line-height: 22px;
    padding: 10px 35px 0 0;
    display: block;
    text-align: right;
    cursor: pointer;
    font-weight: 300;
    &:before{
      content: "";
      position: absolute;
      right: 0px; top: 11px;
      width: 22px; height: 22px;
      border: 1px solid $grey;
    }
    &:after{
      content: "\f00c";
      font-family: FontAwesome;
      position: absolute;
      right: 4px; top: 12px;
      display: none;
      color: $black;
    }

    &.active:after{display: inline-block;}
  }

  &.left {

    label {
      padding: 10px 0 0 35px;
      &:before{
        right: auto; left: 0;
      }
      &:after{
        right: auto; left: 3px;
      }
    }
  }
}

//label
label{font-weight: 400;}
.input-label{line-height: 22px; padding: 10px 0; font-size: 15px; font-weight: 400; margin-top: 20px; margin-bottom: 0}
label.mandatory{position: relative;}
label.mandatory:after{position: absolute; top: 3px; right: -12px; font-family: FontAwesome; content: "\f069"; font-size: 12px; color: $red;}
@media(max-width: 767px){
  .input-label{padding: 0px; margin-top: 0px; top: 15px; position: relative;}
  label.mandatory:after{top: -5px;}
}

#form_submit_notifications{
  font-size: 18px;
  font-weight: 700;
  color: $alert-color;
  text-align: right;
  p {
    margin: 20px 0 10px 0;
  }
}
