header {

  position: relative;
  z-index: 3;

  .top {
    background-color: #5388B9;
    line-height: 20px;
    height: 20px;

    .language-selection {
      float: right;
      width: 60px;

      a {
        color: $white;
        display: inline-block;
        float: left;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        width: 30px;
      }

      .active {
        a {
          background-color: #BBD1E3;
          color: #5388B9;
          cursor: default;
          pointer-events: none;
        }
      }
    }
  }

  .bottom {
    background-color: $white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    position: relative;

    .header-logo {
      margin-top: 7px;
      padding: 10px 0;
      display: inline-block;
      p {
        margin: 0;
        display: inline;
      }

      @media (min-width: 992px) and (max-width: 1199px) {
        img {
          max-width: 260px;
        }
      }

      @media (max-width: 991px) {
        img {
          max-width: 180px;
        }
      }
    }


  }
}

@media (min-width: 768px) {

  #nav-icon {
    display: none;
  }

  .main-navigation {
    float: right;
    padding: 0;
    display: inline-block;

    .acc-trigger {
      display: none;
    }

    > ul > li {

      > a {
        padding: 25px 10px;
      }

      &.active,
      &.onPath,
      &:hover {
        > a {
          background-color: #5287B8;
          color: #fff;
        }
      }

      &.first {
        display: none;
      }

      &:hover {
        > .main-menu-position {
          visibility: visible;
          opacity: 1;
          @include translate(0, -15px);
        }
      }
    }


    li {
      display: inline-block;

      a {
        line-height: 20px;
        display: block;
        font-size: 16px;
        font-weight: 300;
        padding: 5px 10px;
        text-transform: uppercase;
      }
    }

    @media(min-width: 768px) and (max-width: 991px) {

      > ul > li {

        > a {
          padding: 17px 5px;
          font-size: 14px;
        }
      }
    }

    @media(min-width: 992px) and (max-width: 1199px) {

      > ul > li {

        > a {
          padding: 20px 7px;
          font-size: 14px;
        }
      }
    }
  }

  .main-menu-position {
    position: absolute;
    width: 100%;
    left: 0;
    visibility: hidden;
    opacity: 0;
    top: 100%;
    z-index: 1;
    @include translate(0, 10px);
    @include transition-all(0.4s);

    .main-menu-holder {
      background-color: $white;
      border-top: 1px solid #BBD1E3;
      border-bottom: 1px solid #BBD1E3;
      padding: 25px 0 5px 0;

    }

    .columns-holder > ul {
      columns: 4;
      -webkit-columns: 4;
      -moz-columns: 4;

      > li {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        padding: 0 15px 20px 15px;
      }
    }
  }

  .sub-menu.level1 {

    > li {
      display: block;

      > a {
        line-height: 20px;
        padding: 0;
        font-weight: 700;
        text-transform: none;
      }

      &:hover > a {
        text-decoration: underline;
        color: #5287B8;
      }
    }
  }
  .sub-menu.level2 {
    margin-top: 10px;
    li {
      display: block;
      margin-bottom: 10px;
      a {
        line-height: 20px;
        display: inline-block;
        font-size: 16px;
        padding: 0;
        font-weight: 400;
        text-transform: none;
        &:hover {
          text-decoration: underline;
          color: #5287B8;
        }
      }
    }
  }
}

@media (max-width: 767px) {

  .acc-trigger {
    position: absolute;
    right: 0; top: 0;
    width: 30px; height: 30px;
    line-height: 30px;
    text-align: center;
    @include transition-all(0.4s);
    cursor: pointer;
  }

  .open-submenu > .acc-trigger{
    @include transform(rotate(45deg));
  }


  .responsive-menu-opened {
    overflow: hidden;

    .main-navigation {
      left: 0;
    }
  }

  .main-navigation {
    z-index: -1;
    position: fixed;
    top: 0; left: -100%;
    width: 100%; height: 100%;
    background-color: $white;
    padding: 70px 0 0 0;
    @include transition-all(0.4s);

    > ul {
      height: 100%;
      overflow-y: auto;
      padding: 10px;
    }


    .container {
      padding: 0;
    }

    ul ul{
      display: none;
    }



    & ul > li.first {
      display: none;
    }

    > ul > li > a{
      font-weight: 700;
      font-size: 16px;
    }

    li {
      display: block;
      position: relative;

      > a {
        display: block;
        line-height: 20px;
        padding: 5px 0;
      }
    }
  }

  .sub-menu.level1>li>a {
    padding-left: 10px;
  }

  .sub-menu.level2>li>a {
    padding-left: 20px;
  }


  #nav-icon {
    position: absolute;
    right: 10px; top: 5px;
    width: 40px;
    height: 40px;
    padding: 10px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    & span {
      display: block;
      position: absolute;
      height: 2px;
      width: 32px;
      background: #5388B9;
      border-radius: 0;
      opacity: 1;
      left: 4px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      &:nth-child(1) {
        top: 9px;
      }
      &:nth-child(2){
        top: 19px;
      }
      &:nth-child(3){
        top: 19px;
      }
      &:nth-child(4) {
        top: 29px;
      }
    }
    &:hover span{
      background-color: #5388B9;
    }
    &.open span{
      background-color: #5388B9;
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }

}