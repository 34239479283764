@import "reset";

/* FONTS*/
@import "../bower_components/font-awesome/css/font-awesome.min.css";

/* PLUGIN CSS */
@import "../bower_components/bootstrap/dist/css/bootstrap.min.css";
@import "../bower_components/jquery-ui/themes/base/slider.css";
@import "../bower_components/animate.css/animate.min.css";
@import "../bower_components/slick-carousel/slick/slick.css";
//@import "../bower_components/jquery.scrollbar/jquery.scrollbar.css";
//@import "../no_bower_components/glasscase/glasscase_production/css/glasscase.min.css";

// ***********************************
// HELPERS
// ***********************************
@import "variables";
@import "mixins";


// ***********************************
// BASE STYLES
// ***********************************
@import "base";


// ***********************************
// UI ELEMENTS
// ***********************************
@import "ui/sliders";
@import "ui/scrollbars";
//@import "ui/search";
//@import "ui/tabs";
@import "ui/forms";
@import "ui/breadcrumbs";
//@import "ui/selectbox";
@import "ui/pagination";
@import "ui/comments";
//@import "ui/accordion";
//@import "ui/tables";
//@import "ui/glasscase";
//@import "ui/royalslider";


// ***********************************
// SECTIONS
// ***********************************
@import "sections/header";
@import "sections/homepage";
@import "sections/news-events";
@import "sections/simple-page";
@import "sections/resellers";
@import "sections/contact";
@import "sections/about-us";
@import "sections/footer";