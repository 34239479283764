.simple-page {

  .page-title {
    background-color: #87ADCD;
    padding: 40px 0;

    h1 {
      text-align: center;

      span {
        position: relative;
        padding: 0 20px;
        line-height: 40px;
        font-size: 32px;
        font-weight: 400;
        color: $white;
        text-transform: uppercase;
        &:before {
          content: '';
          position: absolute;
          left: 0; top: 60%;
          width: 100%; height: 1px;
          background-color: #87ADCD;
        }
      }
    }
  }

  .text-editor {
    padding-top: 20px;
    padding-bottom: 30px;

    h2 {
      font-size: 36px;
      padding-bottom: 20px;
      margin-bottom: 50px;
      position: relative;
      line-height: 1.6;
      font-weight: 700;
      &:before {
        content: '';
        position: absolute;
        bottom: 0; left: 0;
        width: 150px; height: 7px;
        background-color: #87ADCD;
      }
    }

    h3 {
      font-size: 18px;
      padding-bottom: 15px;
      margin-bottom: 25px;
      position: relative;
      line-height: 1.6;
      font-weight: 700;
      &:before {
        content: '';
        position: absolute;
        bottom: 0; left: 0;
        width: 150px; height: 7px;
        background-color: #87ADCD;
      }
    }

    h4 {
      font-size: 18px;
      font-weight: 400;
      color: #5286B8;
    }

    p {
      line-height: 1.4;
      font-weight: 300;
      font-size: 16px;
    }

    table{
      width: 100%;
      margin-bottom: 60px;

      tr {
        td {
          vertical-align: top;
          font-weight: 300;
          font-size: 16px;
          line-height: 1.4;

          &:nth-of-type(1) {
            width: 58.33333333%;
            padding-right: 15px;

            ul {
              width: 100%;

              li {
                display: block;
                padding-left: 10px;
                position: relative;

                &:before {
                  content: "\f111";
                  font-family: FontAwesome;
                  position: absolute;
                  left: 0; top: 7px;
                  font-size: 6px;
                  display: inline-block;
                }
              }
            }

          }
          &:nth-of-type(2) {
            width: 41.66666667%;
            padding-left: 15px;

            ul {
              width: 100%;
              padding-top: 25px;
              padding-bottom: 5px;

              li {
                display: block;
                padding-left: 30px;
                position: relative;
                line-height: 25px;
                margin-bottom: 15px;

                &:before {
                  content: "\f00c";
                  font-family: FontAwesome;
                  position: absolute;
                  left: 0; top: 0;
                  font-size: 16px;
                  line-height: 25px;
                  display: inline-block;
                  color: #5287B8;
                }
              }
            }

            p {
              margin: 0;
            }
          }

          @media (max-width: 991px) {
            &:nth-of-type(1) {
              display:block;
              width: 100%;
              clear:both;
              padding-right: 0;
            }
            &:nth-of-type(2) {
              display:block;
              width: 100%;
              clear:both;
              padding-left: 0;
              padding-top: 30px;
            }
          }
        }
      }

      .download, .see-more {
        margin-top: 20px;
        padding: 9px 15px;
        line-height: 20px;
        height: 40px;
        border: 1px solid $blue;
        background-color: $blue;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 400;
        color: $white;
        position: relative;
        float: left;
        @include transition-all(0.4s);

        &:hover{
          color: $blue;
          background-color: #EEF3F9;
        }
      }

      .download {
        margin-right: 20px;
      }



    }
  }

  @media (max-width: 767px) {
    .page-title {
      background-color: #87ADCD;
      padding: 20px 0;

      h1 {

        span {
          padding: 0 15px;
          font-size: 18px;
        }
      }
    }

    .text-editor {
      padding-top: 10px;
      padding-bottom: 20px;

      h2 {
        font-size: 26px;
        padding-bottom: 10px;
        margin-bottom: 30px;
      }

      h3 {
        font-size: 16px;
        padding-bottom: 10px;
        margin-bottom: 15px;
      }

      h4 {
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }

      table{
        margin-bottom: 40px;

        tr {
          td {
            font-size: 14px;

            &:nth-of-type(1) {
              padding-right: 10px;

            }
            &:nth-of-type(2) {
              padding-left: 10px;

              ul {
                padding-top: 15px;

                li {
                  padding-left: 20px;
                  line-height: 20px;
                  margin-bottom: 10px;

                  &:before {
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
              }

              p {
                margin: 0;
              }
            }
          }
        }

        .download, .see-more {
          font-size: 14px;
        }

        .download {
          margin-right: 10px;
        }

      }
    }
  }
}


.solution-subpages {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5388b9+0,5388b9+50,87adcd+50,87adcd+50,87adcd+75,bbd1e3+75,bbd1e3+100 */
  background: rgb(83,136,185); /* Old browsers */
  background: -moz-linear-gradient(left,  rgba(83,136,185,1) 0%, rgba(83,136,185,1) 50%, rgba(135,173,205,1) 50%, rgba(135,173,205,1) 50%, rgba(135,173,205,1) 75%, rgba(187,209,227,1) 75%, rgba(187,209,227,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(83,136,185,1) 0%,rgba(83,136,185,1) 50%,rgba(135,173,205,1) 50%,rgba(135,173,205,1) 50%,rgba(135,173,205,1) 75%,rgba(187,209,227,1) 75%,rgba(187,209,227,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(83,136,185,1) 0%,rgba(83,136,185,1) 50%,rgba(135,173,205,1) 50%,rgba(135,173,205,1) 50%,rgba(135,173,205,1) 75%,rgba(187,209,227,1) 75%,rgba(187,209,227,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5388b9', endColorstr='#bbd1e3',GradientType=1 ); /* IE6-9 */
  text-align: right;
  min-height: 380px;
  margin-top: 50px;

  .text {
    color: $white;
    padding: 30px 30px 40px 30px;
    text-align: right;

    .desc {
      font-size: 18px;
      line-height: 1.6;
      font-weight: 300;
      padding-top: 20px;

      p {
        display: inline-block;
        max-width: 420px;
      }
    }

    .read-more {
      background-color: #87ADCD;
    }
  }

  .image-holder {
    padding: 0 30px 0 30px;
    text-align: center;
    overflow: visible;

    img {
      display: inline-block;
      max-width: 100%;
      position: relative;
      margin-top: -50px;
    }
  }

  h3 {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  ul {

    li {
      display: block;
      margin-bottom: 10px;
    }

    a {
      color: $white;
      text-decoration: underline;
      display: inline-block;
      line-height: 25px;
      position: relative;
      font-size: 18px;

      &:hover {
        color: #BBD1E3;
        text-decoration: underline;
      }

      &:before {
        content: "\f00c";
        font-family: FontAwesome;
        position: absolute;
        right: -30px;
        font-size: 16px;
        line-height: 25px;
        display: inline-block;
        color: #87ADCD;
      }
    }
  }
}