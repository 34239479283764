// ***********************************
// PAGINATION
// ***********************************

.pagination-container {
  text-align: center;
}

/* Pagination */
ul.pagination-list{
  padding: 30px 0;
  display: inline-block;
}
ul.pagination-list li{
  display: inline-block;
  width: 40px; height: 40px;
  vertical-align: top;
  float: left;
}
ul.pagination-list li a{
  font-size: 14px;
  font-weight: 600;
  display: block;
  line-height: 38px; width: 100%;
  border: 1px solid #ddd;
  text-align: center;
  color: #5388B9;
  position: relative;
  cursor: pointer;
}
ul.pagination-list li a.disabled{
  cursor: default;
  pointer-events: none;
}
ul.pagination-list li a:hover{
  color: #5388B9;
  background-color: #EEF3F9;
  border-color: #EEF3F9;
  text-decoration: none;
}
ul.pagination-list li a.current{
  cursor: default;
  pointer-events: none;
  color: #5388B9;
  border: 1px solid #5388B9;
  text-decoration: none;
}
ul.pagination-list li:last-of-type{
  margin: 0;
}
@media(max-width: 480px){
  ul.pagination-list  li.hidden-on-mob{
    display: none;
  }
}

ul.pagination-list li.dots{
  letter-spacing: 5px;
  position: relative;
  top: 10px;
}