// ***********************************
// VARIABLES
// ***********************************

// TYPOGRAPHY
$font-family1:  'Roboto Slab', serif;

// COLORS
$white: #fff;
$black: #535353;
$black-dark: #333;
$grey: #888;
$grey-light: #ddd;
$red: #ED3237;
$green: #D9D900;
$blue: #5388B9;

$color1: $black;
$alert-color: $red;
$success-color: $green;
