.about-us-page {

  .page-title {
    background-color: #87ADCD;
    padding: 40px 0;

    h1 {
      text-align: center;

      span {
        position: relative;
        padding: 0 20px;
        line-height: 40px;
        font-size: 32px;
        font-weight: 400;
        color: $white;
        text-transform: uppercase;
        &:before {
          content: '';
          position: absolute;
          left: 0; top: 60%;
          width: 100%; height: 1px;
          background-color: #87ADCD;
        }
      }
    }
  }

  .text-editor {
    padding-top: 20px;
    padding-bottom: 30px;

    h3 {
      font-size: 24px;
      padding-bottom: 15px;
      margin-bottom: 25px;
      position: relative;
      line-height: 1.6;
      font-weight: 700;
      &:before {
        content: '';
        position: absolute;
        bottom: 0; left: 0;
        width: 150px; height: 7px;
        background-color: #87ADCD;
      }
    }

    p {
      line-height: 1.4;
      font-weight: 300;
      font-size: 16px;
    }

    table{
      width: 100%;
      margin-bottom: 60px;

      tr {
        td {
          vertical-align: top;
          font-weight: 300;
          font-size: 16px;
          line-height: 1.4;

          &:nth-of-type(1) {
            width: 66.33333333%;
            padding-right: 100px;
          }
          &:nth-of-type(2) {
            width: 33.66666667%;
            border: 1px solid #BBD1E3;
            padding: 30px;
            p {
              margin: 0;
            }
          }

          @media (max-width: 991px) {
            &:nth-of-type(1) {
              display:block;
              width: 100%;
              clear:both;
              padding-right: 0;
              padding-bottom: 20px;
            }
            &:nth-of-type(2) {
              display:block;
              width: 100%;
              clear:both;
              padding: 20px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .page-title {
      background-color: #87ADCD;
      padding: 20px 0;

      h1 {

        span {
          padding: 0 15px;
          font-size: 18px;
        }
      }
    }

    .text-editor {
      padding-top: 10px;
      padding-bottom: 20px;

      h2 {
        font-size: 26px;
        padding-bottom: 10px;
        margin-bottom: 30px;
      }

      h3 {
        font-size: 16px;
        padding-bottom: 10px;
        margin-bottom: 15px;
      }

      h4 {
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }

      table{
        margin-bottom: 40px;

        tr {
          td {
            font-size: 14px;

            &:nth-of-type(1) {
              padding-right: 10px;

            }
            &:nth-of-type(2) {
              padding: 10px;

              p {
                margin: 0;
              }
            }
          }
        }

      }
    }
  }
}

.banner-on-about-us {
  color: $white;
  background-color: #4775A5;

  .image-holder {
    text-align: center;

    img {
      display: inline-block;
      max-width: 100%;
    }
  }

  .text {
    padding: 40px 0;

    h3 {
      font-size: 32px;
    }

    ul {

      li {
        display: block;
        color: $white;
        position: relative;
        font-size: 16px;
        padding-left: 30px;
        margin-bottom: 3px;

        &:before {
          content: "\f00c";
          font-family: FontAwesome;
          position: absolute;
          left: 0;
          display: inline-block;
          color: #fff;
        }
      }
    }

    @media (max-width: 991px) {

      h3 {
        font-size: 21px;
      }

      ul li {
        font-size: 14px;
      }
    }
  }
}