.slick-arr-top {
  display: inline-block;
  width: 50px;
  position: absolute;
  top: -72px; right: 15px;
  border-bottom: 2px solid $black;
  font-size: 16px;
  line-height: 40px;
  color: $grey;
  text-align: center;
  cursor: pointer;

  &:hover {
    border-color: $red;
  }

  &.slick-prev {
    right: 80px;
  }
}

.slick-arr-bottom {
  display: inline-block;
  width: 50px;
  position: absolute;
  bottom: -55px; left: 50%;
  border-bottom: 2px solid $black;
  font-size: 16px;
  line-height: 40px;
  color: $grey;
  text-align: center;
  cursor: pointer;
  margin-left: 7px;

  &:hover {
    border-color: $red;
  }

  &.slick-prev {
    right: 80px;
    margin-left: -57px;
  }
}

.main-slider {
  height: 100%;

  & .slick-list,
  & .slick-track,
  & .slick-slide {
    height: 100%;
  }

  .slick-arr {
    display: inline-block;
    width: 60px; height: 60px;
    border-radius: 50px;
    font-size: 24px;
    color: $white;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    z-index: 1;
    top: 50%;
    cursor: pointer;
    @include translate(0, -50%);
    @include transition-all(0.4s);

    @media (max-width: 767px) {
      display: none!important;
    }

    span {
      display: block;
      position: absolute;
      left: 50%; top: 50%;
      @include translate(-50%, -50%);
    }

    &.slick-prev {
      left: 20px;
    }
    &.slick-next {
      right: 20px;
    }

    &:hover {
      background-color: rgba(37, 141, 201, 0.6);
    }

    @media (max-width: 480px) {
      width: 30px; height: 30px;
      font-size: 18px;
    }
  }
}

.slick-partners {
  margin-top: 30px;
  margin-bottom: 130px;

  .slick-slide {
    margin: 0 15px;
    opacity:0.5;
    filter: alpha(opacity=50);
    filter: gray;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    @include transition-all(0.4s);
    -webkit-backface-visibility: hidden;

    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
      filter: gray;
      filter: grayscale(0);
      -webkit-filter: grayscale(0);
    }
  }

  img {
    max-height: 75px; max-width: 100%;

    @media (max-width: 1199px) {
      max-height: 60px;
    }
    @media (max-width: 767px) {
      max-height: 40px;
    }
  }
}

.gallery-bottom {
  .slick-arr {
    display: inline-block;
    width: 30px; height: 30px;
    border-radius: 50px;
    font-size: 24px;
    color: $grey-light;
    position: absolute;
    z-index: 1;
    top: 50%;
    cursor: pointer;
    @include translate(0, -50%);
    @include transition-all(0.4s);

    span {
      display: block;
      position: absolute;
      left: 50%; top: 50%;
      @include translate(-50%, -50%);
    }

    &.slick-prev {
      left: 20px;
    }
    &.slick-next {
      right: 20px;
    }
  }
}

.slick-t1 {
  margin-left: -15px;
  margin-right: -15px;


  .slick-slide {
    margin: 0 15px;
  }
}

.slick-t2 {
  margin-left: -15px;
  margin-right: -15px;


  .slick-slide {
    margin: 0 15px;
  }

  .slick-arr-top {
    top: -84px;
    border-bottom: 0;
    border-top: 2px solid $black;

    &:hover {
      border-color: $red;
    }
  }
}