// ***********************************
// BREADCRUMBS
// ***********************************
.breadcrumbs .container{position: relative}
.breadcrumbs ul{margin:0; list-style:none; z-index: 4; line-height: 20px; padding: 10px 0 20px 0}
.breadcrumbs ul li{ float: none; display:inline-block;}
.breadcrumbs ul li:after{
  content: ">";
  display: inline-block;
  width: 6px; text-align: center;
  margin: 0 5px;
}
.breadcrumbs ul li:last-of-type:after{display: none;}
.breadcrumbs ul li:first-child{padding-left:0;}
.breadcrumbs ul li,
.breadcrumbs ul li a,
.breadcrumbs ul li h1 { text-decoration:none; cursor: pointer; position: relative; color: #525252; font-size: 16px; font-weight: 300 }
.breadcrumbs ul li a:hover{ color: $blue; }
.breadcrumbs ul li.current,
.breadcrumbs ul li.current a{cursor: default; text-decoration: underline; pointer-events: none; }

@media (max-width: 991px) {
  .breadcrumbs ul{
    padding: 10px 0 10px 0;
    li a{
      font-size: 12px;
    }
  }
}