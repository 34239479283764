body{
  color: $black;
  font-family: $font-family1;
  font-size: 14px;
  line-height: 1.2;
  overflow-x: hidden;
  width: 100%;
}

@media (max-width: 767px){
  body .container{
    padding-left: 10px;
    padding-right: 10px;
  }
  .row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.narrow-row {
  margin-right: -10px;
  margin-left: -10px;
  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    padding-right: 10px;
    padding-left: 10px;
  }
}

::selection {
  background: $color1; /* WebKit/Blink Browsers */
  color: $white!important;
}
::-moz-selection {
  background: $color1; /* Gecko Browsers */
  color: $white!important;
}

:focus {outline:none;}
::-moz-focus-inner {border:0;}
a:focus, a:active,
button{
  outline: none!important;
}
a{
  color: $black;
  outline: none;
}


a:hover, a:active, a:focus, a:visited {
  @include transition-all(.4s);
  text-decoration: none;
  color: inherit;
}
a.underline:hover, a.underline:active, a.underline:focus, a.underline:visited {
  text-decoration: underline;
}

ul{
  margin: 0;
  padding: 0;
}
ul li{
  list-style-type: none;
}
h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
  font-weight: 400;
}
.sp-body{
  overflow: hidden;
}

img {
  height: auto;
  max-width: 100%;
}

/*js-helper*/
#js_width-helper{width: 1px}
@media(min-width: 768px) and (max-width: 1200px){
  #js_width-helper{width: 768px;}
}
@media(min-width: 1201px) and (max-width: 1440px){
  #js_width-helper{width: 1201px;}
}
@media(min-width: 1441px) and (max-width: 1600px){
  #js_width-helper{width: 1441px;}
}
@media(min-width: 1601px){
  #js_width-helper{width: 1601px;}
}

// custom classes
.relative{position: relative}
.inl-bl{display: inline-block;}
.fl-left{float: left;}
.fl-right{float: right;}
.fl-none{float: none;}
.mar-t{
  margin-top: 15px;
}
.uppercase{text-transform: uppercase;}
.underline{text-decoration: underline;}
.clear{clear: both;}
.center{text-align: center;}
.padd-1{padding: 1px;}
.important{color: $color1;}

.color1{color: $color1;}


.child-block-on-480{
  @media(max-width: 480px){
    & > div{
      width: 100%;
    }
    & > li{
      width: 100%;
    }
  }
}
.block-on-480{
  @media(max-width: 480px){
    width: 100%;
  }
}

.ratio1-1{
  position: relative;
  display: block;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.ratio1-2{
  position: relative;
  display: block;
  &:before {
    content: '';
    display: block;
    padding-top: 50%;
  }
}

.ratio1-3{
  position: relative;
  display: block;
  &:before {
    content: '';
    display: block;
    padding-top: 33.33333333%;
  }
}

.ratio2-3{
  position: relative;
  display: block;
  &:before {
    content: '';
    display: block;
    padding-top: 66.66666667%;
  }
}

.ratio3-4{
  position: relative;
  display: block;
  &:before {
    content: '';
    display: block;
    padding-top: 75%;
  }
}

.image-holder {
  overflow: hidden;
}

.cover-img{
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: auto;
  height: auto;
  max-width: 9999px;
  max-height: 9999px;
  -ms-transform: translate3d(-50%, -50%, 0);
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  &.align-width{
    width:100%; height: auto!important;
  }
  &.align-height{
    height:100%; width: auto!important;
  }
}
.cover-bg{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.parallax-effect {
  position : relative;
  text-indent : -9999px;
  background-attachment : fixed;
  background-position   : top center;
  background-size       : cover;
  height: 70px;
}

.slider-pos-helper {
  margin-left: -10px;
  margin-right: -10px;
}

.subscribe-link {
  text-align: center;
  a {
    display: inline-block;
    font-weight: 700;
    color: $black-dark;
    line-height: 90px;
    font-size: 24px;
    border: 5px solid $color1;
    padding: 0 80px;
    cursor: pointer;
  }

  @media (max-width: 480px) {
    padding: 0 10px;
    a {
      width: 100%;
      padding: 0;
    }
  }
  @media (max-width: 600px) {
    a {
      line-height: 70px;
      font-size: 18px;
    }
  }
  @media (min-width: 1700px) {
    a {
      line-height: 180px;
      font-size: 36px;
      border: 10px solid $color1;
      width: 800px;
    }
  }
}

@media(max-width: 600px){
  .block-on-600{
    width: 100%;
  }
}

@media(max-width: 480px){
  .xs-block{
    width: 100%;
  }
}



.captcha-holder {
  border: 1px solid $grey;
  display: inline-block;
  position: relative;
  padding: 20px 90px 20px 20px;
  float: right;
  max-width: 100%;

  label {
    margin-right: 40px;
  }

  .captcha-logo-and-terms {
    position: absolute;
    right: 0; top: 10px;
    width: 90px;
    text-align: center;

    .captcha-logo {
      width: 55px; height: 50px;
      display: inline-block;
      background: url('../images/captcha-logo.png') no-repeat center;
    }

    .captcha-terms {
      font-size: 9px;
      display: block;
    }
  }

  @media (max-width: 600px) {
    float: none;
    width: 100%;

    label {
      display: inline-block;
      margin-right: 0;
    }
  }
}

.divider {
  border-top: 1px solid #eee;
  span {
    display: block;
    border-top: 1px solid $grey;
    position: relative;
    top: -1px;

    @media(max-width: 767px) {
      &:not(.first){
        display: none;
      }
    }
  }

  &.large {
    padding-bottom: 20px;
  }
}

.blue-divider {
  height: 10px;

  > span {
    display: inline-block;
    float: left;
    width: 25%; height: 10px;

    &:nth-of-type(1) {
      background-color: #5388B9;
    }
    &:nth-of-type(2) {
      background-color: #87ADCD;
    }
    &:nth-of-type(3) {
      background-color: #BBD1E3;
    }
    &:nth-of-type(4) {
      background-color: #EEF3F9;
    }
  }
}

a.read-more {
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  padding: 9px 20px;
  background-color: #5388B9;
  margin-top: 25px;
  border-radius: 6px;
  border: 1px solid #5388B9;
  color: $white;
  width: auto;
  &:hover {
    color: #5388B9;
    background-color: #EEF3F9;
  }
}

.section-title-t1 {
  font-size: 48px;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
  margin: 60px 0 50px 0;
  &:before {
    content: '';
    position: absolute;
    left: 50%; bottom: 0;
    width: 120px; height: 1px;
    background-color: #BBD1E3;
    margin-left: -60px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 36px;
    padding-bottom: 10px;
    margin: 40px 0 30px 0;
  }

  @media (max-width: 767px) {
    font-size: 32px;
    padding-bottom: 5px;
    margin: 20px 0 15px 0;
  }
}

.section-title-t2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  &.white {
    color: $white;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 26px;
    margin-bottom: 15px;
  }

  @media (max-width: 767px) {
    font-size: 21px;
    margin-bottom: 10px;
  }
}