.main-slider {

  .item {
    color: $white;
    position: relative;

    > .container {
      height: 400px;
    }
  }

  .text {
    position: relative;
    top: 50%;
    @include translate(0, -50%);
    max-width: 820px;
  }

  .title {
    font-size: 72px;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 25px;

    &:before {
      content: '';
      position: absolute;
      width: 240px; height: 7px;
      background-color: #87ADCD;
      left: 0; bottom: 0;
    }
  }

  .desc {
    font-size: 24px;
    font-weight: 700;
  }

  .link {
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 20px;
    background-color: #5388B9;
    margin-top: 25px;
    border-radius: 6px;
    color: $white;
    width: auto;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .item {
      color: $white;
      position: relative;

      > .container {
        height: 350px;
      }
    }

    .title {
      font-size: 36px;
      padding-bottom: 15px;
      margin-bottom: 15px;
    }

    .desc {
      font-size: 18px;
    }
  }

  @media (max-width: 767px) {
    .item {

      > .container {
        height: 300px;
      }
    }

    .title {
      font-size: 26px;
      padding-bottom: 10px;
      margin-bottom: 10px;

      &:before {
        width: 180px; height: 5px;
      }
    }

    .desc {
      font-size: 16px;
    }
  }
}

.solutions {
  padding-bottom: 150px;

  .image-holder {
    text-align: center;

    img {
      display: inline-block;
      @media (max-width: 767px) {
        max-width: 200px;
      }
    }
  }

  ul {

    li {
      padding: 10px 0;
    }

    a {
      display: block;
      position: relative;
      padding-left: 25px;
      line-height: 25px;
      font-size: 16px;
      &:before {
        content: "\f00c";
        position: absolute;
        left: 0;
        font-family: FontAwesome;
        color: #5287B8;
      }
    }
  }


  @media (max-width: 767px) {

    h3 {
      margin-top: 20px;
    }
    ul {
      li {
        padding: 5px 0;
      }

      a {
        font-size: 14px;
      }
    }
  }
}

.about-home {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5388b9+0,5388b9+50,87adcd+50,87adcd+50,87adcd+75,bbd1e3+75,bbd1e3+100 */
  background: rgb(83,136,185); /* Old browsers */
  background: -moz-linear-gradient(left,  rgba(83,136,185,1) 0%, rgba(83,136,185,1) 50%, rgba(135,173,205,1) 50%, rgba(135,173,205,1) 50%, rgba(135,173,205,1) 75%, rgba(187,209,227,1) 75%, rgba(187,209,227,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(83,136,185,1) 0%,rgba(83,136,185,1) 50%,rgba(135,173,205,1) 50%,rgba(135,173,205,1) 50%,rgba(135,173,205,1) 75%,rgba(187,209,227,1) 75%,rgba(187,209,227,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(83,136,185,1) 0%,rgba(83,136,185,1) 50%,rgba(135,173,205,1) 50%,rgba(135,173,205,1) 50%,rgba(135,173,205,1) 75%,rgba(187,209,227,1) 75%,rgba(187,209,227,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5388b9', endColorstr='#bbd1e3',GradientType=1 ); /* IE6-9 */
  text-align: right;

  .text {
    color: $white;
    padding: 30px 30px 40px 30px;
    text-align: right;

    .desc {
      font-size: 18px;
      line-height: 1.6;
      font-weight: 300;
      padding-top: 20px;

      p {
        display: inline-block;
        max-width: 420px;
      }
    }

    .read-more {
      border: 1px solid #87ADCD;
      background-color: #87ADCD;
      &:hover {
        background-color: #5388B9;
        color: $white;
      }
    }
  }

  .image-holder {
    padding: 0 30px 0 30px;
    text-align: center;
    overflow: visible;

    img {
      display: inline-block;
      max-width: 100%;
      position: relative;
      margin-top: -50px;
    }
  }
}

.news-events {
  .news-item {
    border: 1px solid #BBD1E3;
    padding: 20px;
    position: relative;
    margin-bottom: 20px;
  }

  .text {
    padding-bottom: 50px;

    @media (max-width: 480px) {
      margin-top: 20px;
    }

    h3 {
      font-size: 16px;
      font-weight: 700;
    }

    .date {
      display: block;
      font-size: 14px;
      font-weight: 300;
      margin: 10px 0 25px 0;
      &:before {
        content: "\f0c2";
        font-family: FontAwesome;
        color: #87ADCD;
        display: inline-block;
        margin-right: 5px;
      }
    }

    .short-desc {
      font-size: 14px;
      line-height: 1.6;
      font-weight: 300;
    }
  }

  .read-more {
    position: absolute;
    right: 20px; bottom: 20px;
    color: $white;
  }

}

.worldwide {
  margin-top: 120px;
  color: $white;
  background-color: #4775A5;

  .image-holder {
    text-align: center;

    img {
      display: inline-block;
      max-width: 100%;
    }
  }

  .text {
    margin-top: 120px;
    margin-bottom: 20px;

    .desc {
      line-height: 1.6;
      font-weight: 300;
      font-size: 24px;
    }

    @media (max-width: 991px) {
      margin-top: 30px;

      .h3 {
        font-size: 21px;
      }

      .desc {
        font-size: 16px;
      }
    }
  }
}