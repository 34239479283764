.resellers-page {
  padding: 60px 0 40px 0;

  @media (max-width: 767px) {
    padding: 0;
  }

  .resellers-list {

    .resellers-list-item {
      padding: 0 0 20px 0;
    }

    .resellers-country {
      border: 1px solid #BBD1E3;
      padding: 10px;

      h3 {
        font-size: 18px;
        padding-bottom: 10px;
        margin-bottom: 15px;
        position: relative;
        line-height: 1.6;
        font-weight: 700;
        &:before {
          content: '';
          position: absolute;
          bottom: 0; left: 0;
          width: 150px; height: 5px;
          background-color: #87ADCD;
        }
      }


      ul {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 5px;

        li {
          display: block;
          padding-left: 20px;
          position: relative;
          line-height: 25px;
          margin-bottom: 10px;

          &:before {
            content: "\f00c";
            font-family: FontAwesome;
            position: absolute;
            left: 0; top: 0;
            font-size: 16px;
            line-height: 25px;
            display: inline-block;
            color: #5287B8;
          }
        }
      }
    }
  }


  @media (min-width: 481px) and (max-width: 991px) {

    .resellers-list { columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;

      .resellers-list-item {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
      }
    }
  }

  @media (min-width: 992px) {
    .resellers-list {
      columns: 3;
      -webkit-columns: 3;
      -moz-columns: 3;
      margin-right: -5px;
      margin-left: -5px;

      .resellers-list-item {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        padding: 0 5px 30px 5px;
      }

      .resellers-country {
        border: 1px solid #BBD1E3;
        padding: 20px 30px 10px 30px;

        h3 {
          font-size: 24px;
          padding-bottom: 15px;
          margin-bottom: 25px;
          position: relative;
          line-height: 1.6;
          font-weight: 700;
          &:before {
            content: '';
            position: absolute;
            bottom: 0; left: 0;
            width: 150px; height: 7px;
            background-color: #87ADCD;
          }
        }


        ul {
          width: 100%;
          padding-top: 10px;
          padding-bottom: 5px;

          li {
            display: block;
            padding-left: 30px;
            position: relative;
            line-height: 25px;
            margin-bottom: 15px;

            &:before {
              content: "\f00c";
              font-family: FontAwesome;
              position: absolute;
              left: 0; top: 0;
              font-size: 16px;
              line-height: 25px;
              display: inline-block;
              color: #5287B8;
            }
          }
        }
      }
    }
  }

}