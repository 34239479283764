footer {
  padding: 30px 0 0 0;
  background-color: #EEF3F9;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6;

  .foo-menu {
    ul {
      margin-top: 20px;

      li {
        position: relative;
        padding-bottom: 5px;

        a {
          display: block;
          padding-left: 20px;
          line-height: 20px;
          &:before {
            content: "\f105";
            font-family: FontAwesome;
            position: absolute;
            left: 5px; top: 0;
            line-height: 20px;
          }
        }
      }
    }
  }

  .title {
    line-height: 30px;
    margin-bottom: 10px;
    font-weight: 700;
  }

  .social-links {
    margin-bottom: 30px;

    a {
      color: #5388B9;
      display: inline-block;
      padding: 0 5px;
      margin-right: 20px;
      line-height: 30px;
    }
  }

  .foo-map {
    height: 120px;
    position: relative;

    iframe {
      position: absolute;
      left: 0; top: 0;
      width: 100%; height: 100%;
    }
  }

  table {
    margin-top: 25px;

    td {
      position: relative;
      padding-left: 30px;
      &:before {
        content: '';
        font-family: FontAwesome;
        position: absolute;
        left: 0; top: 2px;
        color: #5287B8;
        display: inline-block;
        text-align: center;
        width: 15px;
      }
    }

    tbody > tr {
      &:nth-of-type(1) td:before {
        content: "\f095";
      }
      &:nth-of-type(2) td:before {
        content: "\f041";
      }
      &:nth-of-type(3) td:before {
        content: "\f075";
      }
    }
  }

  .foo-bottom {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    border-top: 1px solid #BBD1E3;
    margin-top: 30px;

    a {
      text-decoration: underline;
      color: #5286B8;
    }
  }
}