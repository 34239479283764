.comments-block {

  .comments-top-section {
    border: 1px solid #BBD1E3;
    border-width: 1px 0;
    padding: 40px 0;
  }

  .comments-bottom-section {
    padding: 40px 0;
  }

  .section-title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 15px;

    @media (max-width: 767px) {
      font-size: 26px;
      margin-bottom: 10px;
    }
  }

  .section-subtitle {
    font-size: 16px;
    font-weight: 300;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .reply-on-comment-block {
    display: none;
    padding: 20px 0 0 0;
    position: relative;

    .leave-comment {
      line-height: 20px;
      padding: 10px 0;
      display: inline-block;
    }

    .cancel-reply {
      cursor: pointer;
      color: #86ACCC;
      font-size: 18px;
      line-height: 20px;
      padding: 9px 15px;
      border: 1px solid #86ACCC;
      border-radius: 6px;
      display: inline-block;
      width: auto;
      text-align: center;
      float: right;

      &:hover {
        color: $white;
        background-color: #86ACCC;
      }
    }
  }

  .comment-form {
    //    input,
    //    textarea {
    //      padding: 14px 10px;
    //    }
    textarea {
      min-height: 150px;
    }

    .btn-t1 {
      width: auto;
      position: relative;
      left: 50%;
      @include translate(-50%, 0);
    }
  }
}

.comment-error {
  span {
    display: block;
    margin-top: 20px;
    color: $red;
    opacity: 0.8;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    padding: 5px 0;
  }
}

.captcha-row {
  p.captcha-text {
    display: table;
    margin: 20px 0 0 0;
    height: 30px;
    span {
      display: table-cell;
      vertical-align: middle;
      line-height: 15px;font-weight: 300;
    }

    @media (max-width: 767px) {
      display: table;
      margin: 0;
      position: relative;
      top: 10px;
    }
  }

  .captcha-img-holder {
    position: relative;
    padding-left: 90px;

    img {
      position: absolute;
      left: 0; top: 20px;
      height: 30px; width: 90px;
    }
  }
}

.comment {
  overflow: hidden;

  .comment-position-helper {
    position: relative;
    padding: 20px 0 20px 120px;
    border-bottom: 1px solid #EEF3F9;
    min-height: 130px;
  }

  .comment {

    .comment-position-helper {
      @include translate(30px, 0);
      padding-right: 30px;
    }
  }

  .comment-avatar {
    position: absolute;
    left: 0; top: 20px;
  }

  .comment-author {
    font-size: 16px;
    font-weight: 400;
    display: block;
    margin-bottom: 5px;
  }

  .comment-date {
    font-size: 14px;
    color: #5388B9;
    display: block;
    margin-bottom: 5px;
  }

  .comment-text {
    font-size: 16px;
    font-weight: 300;
  }

  .comment-reply {
    color: #86ACCC;
    font-size: 18px;
    line-height: 20px;
    padding: 9px 0;
    border: 1px solid #86ACCC;
    border-radius: 6px;
    display: inline-block;
    width: 60px;
    text-align: center;
    margin-top: 20px;

    &:hover {
      color: $white;
      background-color: #86ACCC;
    }
  }

  @media (max-width: 767px) {
    .comment-position-helper {
      padding: 10px 0 10px 60px;
      min-height: 70px;
    }

    .comment {

      .comment-position-helper {
        @include translate(20px, 0);
        padding-right: 20px;
      }
    }

    .comment-avatar {
      top: 10px;
      width: 50px; height: 50px;
    }

    .comment-author {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .comment-date {
      font-size: 12px;
    }

    .comment-text {
      font-size: 14px;
    }

    .comment-reply {
      font-size: 16px;
    }
  }
}