.contact-page .container{
  position: relative;
  z-index: 0;
}

.contact-page {

  .page-title {
    background-color: #87ADCD;
    padding: 40px 0;

    h1 {
      text-align: center;

      span {
        position: relative;
        padding: 0 20px;
        line-height: 40px;
        font-size: 32px;
        font-weight: 400;
        color: $white;
        text-transform: uppercase;
        &:before {
          content: '';
          position: absolute;
          left: 0; top: 60%;
          width: 100%; height: 1px;
          background-color: #87ADCD;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .page-title {
      background-color: #87ADCD;
      padding: 20px 0;

      h1 {

        span {
          padding: 0 15px;
          font-size: 18px;
        }
      }
    }
  }
}

.contact-page_table{

  .border-frame {
    border: 1px solid #BBD1E3;
    padding: 20px;
    margin-top: 20px;

  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    text-transform: capitalize;
    padding-bottom: 15px;
    position: relative;
    margin-bottom: 20px;
    &:before {
      content: '';
      position: absolute;
      left: 0; bottom: 0;
      height: 7px; width: 150px;
      background-color: #87ADCD;
    }
  }

  & table{
    border: 0;
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
  }

  & td:before{
    font-family: 'FontAwesome';
    content:"";
    line-height: 30px;
    position: absolute; left: 0px; top: 50%; margin-top: -15px;
    width: 30px;
    text-align: center;
    display: inline-block;
    color: #5287B8;
  }

  & tr:nth-of-type(1) td:before{content: "\f095";}
  & tr:nth-of-type(2) td:before{content: "\f041";}
  & tr:nth-of-type(3) td:before{content: "\f075";}

  & td{
    border: 0;
    padding: 15px 0 15px 40px;
    position: relative;

  }
}

.contact-page_form_mandatory{
  text-align: right;
  padding-right: 15px;
  position: relative;
  color: $red;
  line-height: 20px;
  margin: 65px 0 0 0;
  &:after{
    position: absolute;
    top: -4px;
    right: 2px;
    font-family: FontAwesome;
    content: "\f069";
    font-size: 12px;
    color: $red;
  }
}
.fake-placeholder {
  position: absolute;
  line-height: 20px;
  padding: 9px 15px;
  height: 40px;
  margin-top: 20px;
  z-index: 1;

  & span{
    position: relative;
    display: inline-block;
    padding-right: 15px;
  }

  &.mandatory span:after{
    position: absolute;
    top: -4px;
    right: 2px;
    font-family: FontAwesome;
    content: "\f069";
    font-size: 12px;
    color: $red;
  }
}


#contactForm{padding-bottom: 30px;}
#contactForm .bottom-part{
  position: relative;
  .captcha-holder {
    margin-top: 20px;
  }
}

#contact_form_error {
  p {
    font-size: 18px;
    color: $red;
    font-weight: 700;
    margin: 20px 0 0 0;
  }
}