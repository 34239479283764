.news-events-list {

  .item {
    border: 1px solid #BBD1E3;
    margin-bottom: 20px;
    position: relative;

    .read-more {
      display: none;
    }
  }

  .text {
    padding: 10px 20px 10px 0;

    h2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.4;
    }

    .date {
      display: block;
      font-weight: 300;
      margin-top: 5px;
      margin-bottom: 10px;
      font-size: 12px;

      span {
        color: #87ADCD;
      }
    }

    .short {
      font-size: 14px;
      line-height: 1.4;
      font-weight: 300;
    }
  }

  .inverted .text {
    padding-right: 0;
    padding-left: 20px;
  }

  @media (max-width: 767px) {

    .text {
      padding: 10px;
    }

    .inverted .text {
      padding-right: 10px;
      padding-left: 10px;
    }
  }


  @media (min-width: 992px) {
    .item {
      margin-bottom: 30px;

      .read-more {
        display: inline-block;
        position: absolute;
        right: 20px; bottom: 20px;
      }

      &.even .read-more {
        left: 20px; right: auto;
      }
    }

    .text {
      padding: 20px 20px 70px 0;

      h2 {
        font-size: 24px;
      }

      .date {
        margin-bottom: 15px;
        font-size: 14px;
      }

      .short {
        font-size: 16px;
      }
    }

    .inverted .text {
      padding-right: 0;
      padding-left: 20px;
    }
  }
}





.news-events-page {
  padding-bottom: 20px;
  padding-top: 10px;

  .social-links {
    text-align: center;
    padding: 20px;

    a {
      display: inline-block;
      width: 30px; line-height: 30px;
      font-size: 16px;
      color: #5388B9;
      margin: 0 15px;
      &:hover {
        color: #86ACCC;
      }
    }
  }

  .text {

    h1 {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.4;

      @media (max-width: 767px) {
        margin-top: 20px;
      }
    }

    .date {
      display: block;
      font-weight: 300;
      margin-top: 5px;
      margin-bottom: 10px;
      font-size: 12px;

      span {
        color: #87ADCD;
      }
    }

    .short {
      font-size: 14px;
      line-height: 1.4;
      font-weight: 300;
    }
  }

  .long {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 300;
    margin-top: 30px;
  }

  .option-links {
//    margin-top: 20px;
    > a.fa {
      font-size: 18px;
      line-height: 20px;
      padding: 9px 0;
      color: #86ACCC;
      border: 1px solid #87ADCD;
      width: 60px;
      margin-right: 10px;
      text-align: center;
      border-radius: 6px;
      &:hover {
        background-color: #86ACCC;
        color: $white;
      }
    }
  }


  @media (min-width: 992px) {
    padding-bottom: 40px;
    padding-top: 60px;

    .text {

      h1 {
        font-size: 24px;
      }

      .date {
        margin-bottom: 15px;
        font-size: 14px;
      }

      .short {
        font-size: 16px;
      }
    }

    .long {
      font-size: 16px;
      margin-top: 50px;
    }
  }
}

.gallery-display {
  opacity: 0;
  visibility: hidden;
  @include transition-all(0.2s);

  &.visible-gallery {
    opacity: 1;
    visibility: visible;
  }
}

.gallery-bottom {
  padding: 15px 60px;
  border: 1px solid #BBD1E3;
  .item {
    padding: 0 10px;
  }

  @media (max-width: 480px) {
    padding: 10px 10px;
    border: 1px solid #BBD1E3;
    .item {
      padding: 0 5px;
    }
  }
}